import { modalStore } from "Stores/ModalStore";
import { WholeAppLoaderModal } from "UIKit/WholeAppLoaderModal/WholeAppLoaderModal";

export const WHOLE_APP_MODAL_NAME = "wholeAppLoading";

export const openWholeAppLoaderModal = (text?: string) => {
  modalStore.openModal({
    variant: "component",
    Component: () => <WholeAppLoaderModal text={text} />,
    name: WHOLE_APP_MODAL_NAME,
    isDismissible: false,
  });
};
