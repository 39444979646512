import {
  EolasBaseFile,
  isDefined,
  Maybe,
  NoAccessContentItemResponse,
  NoAccessSectionResponse,
  SectionNonAppsync,
  sectionStore,
} from "@eolas-medical/core";
import { useFetchContentItemById } from "modules/contentRepository/data/useFetchContentItemById";
import { useFetchContentSectionById } from "modules/contentRepository/data/useFetchContentSectionById";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { useLocation } from "react-router-dom";
import { getSearchParams } from "Utilities/helpers";

export const useGetSpaceOrgOwnerIds = (): {
  spaceId: string | null;
  organisationId: string | null;
  didError: boolean;
  isLoading: boolean;
} => {
  const location = useLocation();
  const deeplinkRoot = location.pathname.split("/")[1];

  const { fileId, checklistId, sectionId } = getSearchParams({
    searchString: location.search,
    paramNameList: ["fileId", "checklistId", "sectionId"],
  });

  const localSection = sectionId ? sectionStore.getSection(sectionId) : undefined;
  const { file: localFile } = useGetFile({ id: fileId });
  const localChecklist = checklistId ? sectionStore.getSection(checklistId) : undefined;

  const doesLocalSectionExist = isDefined(localSection?.id);
  const doesLocalChecklistExist = isDefined(localChecklist?.id);

  const isFetchSectionEnabled =
    isDefined(sectionId) && isDeeplinkRoot(deeplinkRoot) && !doesLocalSectionExist;
  const isFetchContentItemEnabled = isDefined(fileId) && isDeeplinkRoot(deeplinkRoot) && !localFile;
  const isFetchChecklistEnabled =
    isDefined(checklistId) && isDeeplinkRoot(deeplinkRoot) && !doesLocalSectionExist;

  const {
    contentItem,
    isLoading: isFetchingContentItem,
    isError: isContentItemError,
  } = useFetchContentItemById({
    contentItemId: fileId ?? "",
    isEnabled: isFetchContentItemEnabled,
  });

  const {
    contentSection,
    isLoading: isFetchingContentSection,
    isError: isContentSectionError,
  } = useFetchContentSectionById({
    sectionId: checklistId || sectionId || "",
    isEnabled: isFetchChecklistEnabled || isFetchSectionEnabled,
  });

  if (!isDeeplinkRoot(deeplinkRoot)) {
    return { spaceId: null, organisationId: null, didError: true, isLoading: false };
  }

  const file = localFile ?? contentItem;
  const checklist = checklistId
    ? doesLocalChecklistExist
      ? localChecklist
      : contentSection ?? null
    : null;
  const section = sectionId
    ? doesLocalSectionExist
      ? localSection
      : contentSection ?? null
    : null;
  const isError = isContentItemError || isContentSectionError;
  const isLoading = isFetchingContentItem || isFetchingContentSection;

  const spaceId =
    deeplinkRoot === "spaces"
      ? getOwnerIdFromEntities({ item: file ?? section ?? checklist })
      : null;

  const organisationId =
    deeplinkRoot === "organisation" ? getOwnerIdFromEntities({ item: file ?? section }) : null;

  return { spaceId, organisationId, didError: isError, isLoading: isLoading ?? false };
};

const isDeeplinkRoot = (deeplinkRoot: string): deeplinkRoot is "organisation" | "spaces" => {
  return deeplinkRoot === "organisation" || deeplinkRoot === "spaces";
};

const getOwnerIdFromEntities = ({
  item,
}: {
  item: Maybe<
    EolasBaseFile | SectionNonAppsync | NoAccessContentItemResponse | NoAccessSectionResponse
  >;
}): string | null => item?.ownerID ?? null;
