import { useTranslation } from "react-i18next";
import { Loader } from "UIKit/Loader";
import { InnerModalWrapper, ModalBody, ModalHeader } from "UIKit/Modal";
import { Title } from "UIKit/Typography";

export const WholeAppLoaderModal = ({ text }: { text?: string }) => {
  const { t } = useTranslation();
  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{text || t("whole_app_loading")}</Title>
      </ModalHeader>
      <ModalBody className="items-center justify-center">
        <Loader />
      </ModalBody>
    </InnerModalWrapper>
  );
};
