import { useQuery } from "@tanstack/react-query";
import { GreenTickIcon } from "Assets";
import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, Text } from "UIKit";
import { useSpacesContext } from "modules/spaces";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getPathToItemOrSection } from "shared/pages/ContentRepository/ContentItems/functions/getPathToItemOrSection";

type Props = {
  destinationSectionId: string;
};

export const CopyItemsSuccess = ({ destinationSectionId }: Props) => {
  const { isAdmin: isInAdminMode, encodedSelectedSpaceName } = useSpacesContext();
  const { t } = useTranslation();
  const { push } = useHistory();

  const { data: pathToLocation } = useQuery({
    queryKey: [
      "copyItemsSuccessPathToSection",
      destinationSectionId,
      isInAdminMode,
      encodedSelectedSpaceName,
    ],
    queryFn: async () =>
      getPathToItemOrSection({
        id: destinationSectionId,
        isInAdminMode,
        encodedSelectedSpaceName: encodedSelectedSpaceName || null,
      }),
  });

  const canNavigateToSection = typeof pathToLocation === "string";

  const handleNavigateToSection = (path: string) => {
    push(path);
    modalStore.closeModal();
  };

  return (
    <InnerModalWrapper>
      <div className="flex flex-1 flex-col items-center justify-center p-8">
        <GreenTickIcon className="h-16 w-16" />
        <Text level={1} className="mt-4">
          {t("copy_files_modal_success")}
        </Text>
        {canNavigateToSection ? (
          <div className="flex w-full flex-row items-center justify-center gap-2 mt-6">
            <Button
              size="lg"
              className="w-1/4"
              onClick={() => modalStore.closeModal()}
              variant="solid"
              color="grey"
            >
              {t("copy_files_modal_success_button_secondary")}
            </Button>
            <Button
              size="lg"
              onClick={() => handleNavigateToSection(pathToLocation)}
              variant="solid"
              className="w-1/4"
              color="blue"
            >
              {t("copy_files_modal_success_button_primary")}
            </Button>
          </div>
        ) : null}
      </div>
    </InnerModalWrapper>
  );
};
