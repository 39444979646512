import { Timeout } from "Utilities/helpers";

const DEFAULT_TIMEOUT = 5000;

export const debounceWithCancel = <T extends () => unknown>({
  callback,
  timeout,
}: {
  callback: T;
  timeout?: number;
}) => {
  let timer: Timeout | null = null;

  const debouncePromise = new Promise<void>((resolve) => {
    timer = setTimeout(() => {
      callback();
      resolve();
      timer = null;
    }, timeout || DEFAULT_TIMEOUT);
  });

  return { debouncePromise, cancelDebounce: () => timer && clearTimeout(timer) };
};
