import React from "react";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents, EolasFile } from "@eolas-medical/core";

import { openWindow } from "Hooks";
import { useSentry } from "Contexts";
import { fromAwsJSON } from "Utilities";

export const useMedusaFile = () => {
  const disabledRef = React.useRef(false);
  const { addBreadcrumb } = useSentry();

  const onSelectMedusaFile = React.useCallback(
    async (file: EolasFile) => {
      // disable the handler while the request is pending
      if (disabledRef.current) return;

      trackEvent(AnalyticsEvents.MEDUSA_MONOGRAPH_VIEWED);

      disabledRef.current = true;

      addBreadcrumb({
        category: "Medusa Files",
        message: `Opening Medusa File: ${file.name}`,
        data: {
          id: file.id,
          key: file.key,
        },
      });

      const { type, key, metadata } = file;

      let url = key ?? "";

      if (type !== "link" || !url) {
        url = fromAwsJSON(metadata ?? "{}").source ?? "";
      }

      openWindow(url);

      disabledRef.current = false;
    },
    [addBreadcrumb],
  );

  return {
    onSelectMedusaFile,
  };
};
