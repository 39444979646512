import { AppLevelSection, sectionStore } from "@eolas-medical/core";
import { useMemo } from "react";
import { useCompleteFile } from "Hooks";
import { NewsFeed } from "../types";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

interface useNewsFeeds {
  newsFeeds: NewsFeed[];
}

const useNewsFeeds = (): useNewsFeeds => {
  const { getCompletedFileProps } = useCompleteFile();

  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.newsFeed) ?? {
    id: "",
  };

  const { files } = useGetFiles({ sectionId: id });

  const newsFeeds: NewsFeed[] = useMemo(() => {
    return files.map((f) => {
      return {
        ...f,
        isHighlighted: !!f.highlighted && f.highlighted !== "false",
        ...getCompletedFileProps(f),
        isRead: getCompletedFileProps(f).isCompleted,
        createdBy: f.info,
      };
    });
  }, [files, getCompletedFileProps]);

  return { newsFeeds };
};

export default useNewsFeeds;
