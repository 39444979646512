import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "UIKit";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

import EditContentItemModal from "../EditContentItemModal/EditContentItemModal";
import DeleteContentItemModal from "../DeleteContentItemModal/DeleteContentItemModal";
import ContentItemTile from "../ContentItemTile/ContentItemTile";
import EditFlashcardModal from "../EditFlashcardItemModal/EditFlashcardItemModal";
import useDownloadContentFile from "modules/contentRepository/data/useDownloadContentFile";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import {
  ContentItem,
  isLinkContentItem,
  isFlashCardContentItem,
  isEolasEditorContentItem,
  SupportedFileType,
  eolasLogger,
  hasStringProp,
  ShadowCopyOpenedPayload,
  AnalyticsEvents,
} from "@eolas-medical/core";
import EditEolasEditorItemModal from "../EditEolasEditorItemModal/EditEoalsEditorItemModal";
import { useShareFile } from "shared/hooks/useShareFile";
import { convertToLink } from "Utilities";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { SelectFileToggleProps } from "UIKit/Button/SelectFileToggle";
import { PublishContentItemModal } from "../PublishContentItemModal/PublishContentItemModal";
import { modalStore } from "Stores/ModalStore";
import { observer } from "mobx-react-lite";
import { CopyItemsModal } from "Pages/Spaces/components/CopyItemsModal/CopyItemsModal";
import { getOriginalFromShadowCopy } from "../../functions/getOriginalFromShadowCopy";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { getPathToShadowCopyOriginal } from "../../functions/getPathToShadowCopyOriginal";
import { useNotifications } from "Components/Notifications";
import { isShadowCopyContentItem } from "../../functions/typeguards";
import { trackEvent } from "API/Analytics";
import { useSpacesContext } from "modules/spaces";
import { ShowVersionsModal } from "../ShowVersionsModal/ShowVersionsModal";
import { IsCopyableStatus } from "./types";
import { ManageContentItemWizardModal } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/ManageContentItemWizardModal";
import { useDeleteParallelDraft } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useDeleteParallelDraft";
import { useUnpublishItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useUnpublishItem";
import { useGetDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useGetDraftFile";
import {
  BaseContentItemSearchParams,
  ContentItemParams,
} from "shared/pages/ContentRepository/context/types";
import { usePublishItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/usePublishItem";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

interface ContentItemInstanceProps {
  contentItem: ContentItem;
  isAdminMode: boolean;
  flashcardsHaveSpecialties?: boolean;
  "data-testid"?: string;
  isDraggable?: boolean;
  isCopyableStatus?: IsCopyableStatus;
  isCopyable?: boolean;
  isSearchResult?: boolean;
}

const ContentItemInstance = observer(
  ({
    contentItem,
    isAdminMode,
    flashcardsHaveSpecialties = false,
    "data-testid": dataTestId,
    isDraggable = false,
    isCopyableStatus,
    isSearchResult = false,
    ...rest
  }: ContentItemInstanceProps & Partial<SelectFileToggleProps>) => {
    const { flags } = useLaunchDarkly();
    const { t } = useTranslation();
    const { push } = useHistory();
    const [modal, setModal] = useState<React.ReactNode>(null);
    const { showNotification } = useNotifications();
    const { encodedSelectedSpaceName } = useSpacesContext();
    const { lastUpdatedTimestamp } = contentDbStore;

    const {
      hasCopilotData,
      getBlobViewerUrl,
      getFlashcardViewerUrl,
      getEolasEditorViewerUrl,
      onLoadContentItem,
      contentItemConfig,
      ...restContextRepositoryContext
    } = useContentRepositoryContext();

    /*
     * TODO: separate out this component from knowledge content and have it consume an EolasFile
     * Do NOT build anymore mapping EolasFile data via the mapToContentItem function
     * Any further functionality / new data should be derived from an EolasFile
     */

    const isSponsoredSlotItem =
      restContextRepositoryContext.contextDomain === "knowledge" &&
      (restContextRepositoryContext.knowledgeContentDomain === "sponsoredSlots" ||
        restContextRepositoryContext.knowledgeContentDomain === "sponsoredSlotsContent");

    const { contextDomain } = restContextRepositoryContext;

    const { file: eolasFile } = useGetFile({
      id: contextDomain === "spaceOrg" ? contentItem.id : null,
    });

    const { deleteParallelDraft } = useDeleteParallelDraft(eolasFile);
    const { unpublishItem } = useUnpublishItem(eolasFile);
    const { publishItem } = usePublishItem(eolasFile);
    const { data: draftFileData } = useGetDraftFile(
      isAdminMode ? contentItem.id : null,
      lastUpdatedTimestamp,
    );

    const hideExpiryDate =
      !!flags[LDFlagNames.HIDE_EXPIRY_DATE_ON_GCR_TILE] || contentItemConfig?.shouldHideExpiryDate;
    const expiryDate = hideExpiryDate ? undefined : contentItem.expiryDate;

    const {
      isFavouriteModalOpen,
      showRemoveFavouriteModal,
      closeFavouriteModal,
      removeFavouriteModal,
    } = useRemoveFavouriteModal();

    const { downloadContentFile } = useDownloadContentFile(contentItem);
    const { isShareFileEnabled, isShareModalOpen, closeShareModal, handleOnShareFile, shareModal } =
      useShareFile();

    const shouldShowShareButton = isShareFileEnabled && contentItemConfig?.shouldAllowSharing;

    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const handleCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      setModal(null);
    };

    const handleOnClickCopyTo = () => {
      modalStore.openModal({
        name: "selectCopyTargetModal",
        variant: "component",
        Component: <CopyItemsModal initialItems={[contentItem]} />,
      });
    };

    const handleShowVersions = () => {
      modalStore.openModal({
        name: "showVersionsModal",
        variant: "component",
        Component: (
          <ShowVersionsModal
            contentId={contentItem.id}
            handleClickVersionPreview={handleClickItem}
          />
        ),
      });
    };

    const handleClickItem = useCallback(
      (originalItem: ContentItem, versionNo?: number, shadowCopyContentId?: string) => {
        const onClick = async ({
          item,
          shouldOpenDraftFile,
        }: {
          item: ContentItem;
          shouldOpenDraftFile?: boolean;
        }) => {
          let content = item;

          const params: BaseContentItemSearchParams = {
            [ContentItemParams.shadowCopyContentId]: shadowCopyContentId,
            [ContentItemParams.shouldShowBreadcrumbs]:
              contentItemConfig?.shouldShowBreadcrumbs !== undefined
                ? contentItemConfig?.shouldShowBreadcrumbs.toString()
                : undefined,
          };

          if (shouldOpenDraftFile && draftFileData?.draftFile) {
            content = mapToContentItem(draftFileData.draftFile);
            params[ContentItemParams.fileIdOfDraftFile] = item.id;
          }

          if (isShadowCopyContentItem(content)) {
            const maybeOriginalFile = await getOriginalFromShadowCopy(content);
            const maybeOriginalContentItem = maybeOriginalFile
              ? mapToContentItem(maybeOriginalFile)
              : null;
            if (maybeOriginalContentItem) {
              trackEvent<ShadowCopyOpenedPayload>(AnalyticsEvents.SHADOW_COPY_OPENED, {
                origin: "directNavigation",
                fileId: item.id,
              });
              handleClickItem(maybeOriginalContentItem, undefined, item.id);
              return;
            }

            // If we can't find the original content item, use the deeplink on the shadow copy
            push(convertToLink(content.deepLinkUrl));
            return;
          }

          if (isEolasEditorContentItem(content)) {
            modalStore.closeModal();
            push(getEolasEditorViewerUrl({ eolasEditor: content, versionNo, ...params }));
            return;
          }

          if (isLinkContentItem(content)) {
            if (onLoadContentItem) {
              onLoadContentItem(content);
            }
            window.open(convertToLink(content.url), "_blank");
            return;
          }

          if (isFlashCardContentItem(content)) {
            modalStore.closeModal();
            push(getFlashcardViewerUrl({ flashcard: content, versionNo, ...params }));
            return;
          }

          push(getBlobViewerUrl({ blob: content, versionNo, ...params }));
        };

        if (isAdminMode && eolasFile && eolasFile.hasDrafts) {
          modalStore.openModal({
            icon: "warning",
            variant: "dialogue",
            layoutType: "stacked",
            name: "confirmAdminView",
            title: t("repository_admin_view_draft_title"),
            message: t("repository_admin_view_draft_desc"),
            onConfirm: () => onClick({ item: originalItem }),
            onDismiss: () => onClick({ item: originalItem, shouldOpenDraftFile: true }),
            confirmText: t("view_published"),
            dismissText: t("view_draft"),
            isDismissible: true,
          });
          return;
        }
        onClick({ item: originalItem });
      },
      [
        push,
        getBlobViewerUrl,
        getFlashcardViewerUrl,
        getEolasEditorViewerUrl,
        onLoadContentItem,
        isAdminMode,
        eolasFile,
        t,
        draftFileData,
        contentItemConfig,
      ],
    );

    const handleEditSection = () => {
      if (isShadowCopyContentItem(contentItem)) {
        eolasLogger.error(
          new Error("Cannot edit shadow copy content item -- this should never happen"),
          { contentItem },
        );
        return;
      }

      if (contextDomain === "spaceOrg") {
        modalStore.openModal({
          variant: "component",
          name: "editContentItem",
          Component: (
            <ManageContentItemWizardModal
              sectionId={contentItem.parentId}
              fileId={contentItem.id}
            />
          ),
        });
        return;
      }

      if (isFlashCardContentItem(contentItem)) {
        setModal(
          <EditFlashcardModal
            flashcard={contentItem}
            hasSpecialties={flashcardsHaveSpecialties}
            hasCopilotData={hasCopilotData}
            onClose={handleCloseModal}
          />,
        );
      } else if (isEolasEditorContentItem(contentItem)) {
        setModal(<EditEolasEditorItemModal contentItem={contentItem} onClose={handleCloseModal} />);
      } else {
        setModal(<EditContentItemModal contentItem={contentItem} onClose={handleCloseModal} />);
      }
    };

    const handleDeleteItem = () => {
      if (eolasFile?.hasDrafts) {
        deleteParallelDraft();
        return;
      }
      setModal(<DeleteContentItemModal contentItem={contentItem} onClose={handleCloseModal} />);
    };

    const handleDownloadFile = () => {
      downloadContentFile();
    };

    const onPublishDraft = () => {
      if (contextDomain === "spaceOrg") {
        publishItem();
        return;
      }
      setModal(<PublishContentItemModal contentItem={contentItem} onClose={handleCloseModal} />);
    };

    const handleGoToOriginal = async () => {
      if (!isShadowCopyContentItem(contentItem)) {
        showNotification({
          type: "error",
          description: t("content_item_go_to_original_error"),
        });
        return;
      }

      const result = await getPathToShadowCopyOriginal({
        shadowItem: contentItem,
        isInAdminMode: isAdminMode,
        encodedSelectedSpaceName: encodedSelectedSpaceName || null,
      });

      if (hasStringProp(result, "error")) {
        eolasLogger.error(new Error("Error getting content item path"), { result });
        showNotification({
          type: "error",
          description: t("content_item_go_to_original_error"),
        });
        return;
      }

      push(result);
    };

    const isFlashcard = isFlashCardContentItem(contentItem);

    const contentItemFileType = useMemo<SupportedFileType>(() => {
      if (contentItem.media && typeof contentItem.media.type === "string") {
        return contentItem.media.type as SupportedFileType;
      }

      if (contentItem.type === "EOLAS_EDITOR") {
        return "eolas-editor";
      }

      if (isFlashcard) {
        return "flashcard";
      }

      // Default case, ensure "link" is part of SupportedFileType
      return "link";
    }, [contentItem, isFlashcard]);

    return (
      <>
        <Modal open={!!isModalOpen} onClose={handleCloseModal}>
          {modal || shareModal || removeFavouriteModal}
        </Modal>
        <div id={contentItem.id} data-testid="content-section-list-item">
          <ContentItemTile
            eolasFile={eolasFile}
            isSearchResult={isSearchResult}
            item={contentItem}
            isInAdminMode={isAdminMode}
            contentType={contentItemFileType}
            description={isFlashcard ? contentItem.description : null}
            date={isFlashcard ? contentItem.createdAt : expiryDate}
            dateLabel={isFlashcard ? t("general_date") : t("repository_item_expiry")}
            imageUrl={isFlashcard ? contentItem.url : null}
            onClick={() => handleClickItem(contentItem)}
            onEdit={isAdminMode ? handleEditSection : undefined}
            onDelete={isAdminMode ? handleDeleteItem : undefined}
            onShowVersions={isAdminMode ? handleShowVersions : undefined}
            onDownload={handleDownloadFile}
            data-testid={dataTestId}
            isDraggable={isDraggable}
            onPublishDraft={onPublishDraft}
            onShare={shouldShowShareButton ? () => handleOnShareFile(contentItem) : undefined}
            showRemoveFavouriteModal={showRemoveFavouriteModal}
            shouldAllowFavourite={contentItemConfig?.shouldAllowFavourites}
            onCopy={isCopyableStatus ? handleOnClickCopyTo : undefined}
            onGoToOriginal={isAdminMode ? handleGoToOriginal : undefined}
            onUnpublish={unpublishItem}
            shouldTruncateDescription={!isSponsoredSlotItem}
            {...rest}
          />
        </div>
      </>
    );
  },
);

export default ContentItemInstance;
