import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import { MeLevelSection, ProfileVerificationStatus, userStore } from "@eolas-medical/core";

import { GridContainer, InnerPageWrapper, MainSectionTile } from "UIKit";
import { MyFavouritesIcon, MyFilesIcon, PortfolioHomepageIcon } from "Assets";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import ProfileDetails from "./pages/MyProfile/components/ProfileDetails/ProfileDetails";
import { useShowProfileVerification } from "Hooks/useShowProfileVerification/useShowProfileVerification";
import { hasNotSeenFeatureGuide } from "Components/FeatureGuideModal/hooks/useFeatureGuideModal";
import useUserDetails from "Hooks/useUserDetails";

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

export const MeTab: React.FC<RouteComponentProps> = ({ match: { url } }: RouteComponentProps) => {
  const { t } = useTranslation();
  const { userData: user } = userStore;
  const { showProfileVerification } = useShowProfileVerification();

  const { givenName, familyName, photoUrl, profileVerification } = user;

  const { flags } = useLaunchDarkly();
  const { isSharedAccount } = useUserDetails();
  const { push } = useHistory();

  const isPortfolioActive = flags[LDFlagNames.TEACHING_DELIVERY];
  const isMyFilesActive = flags[LDFlagNames.MY_FILES];
  const isMyFavouritesActive = flags[LDFlagNames.MY_FAVOURITES];
  const shouldShowNewTagOnMeTab =
    hasNotSeenFeatureGuide(MeLevelSection.myFavourites) &&
    flags[LDFlagNames.MY_FAVOURITES] &&
    !isSharedAccount;

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center items-center">
        <ProfileDetails
          firstName={givenName || ""}
          lastName={familyName || ""}
          imageUrl={photoUrl || ""}
          verificationStatus={
            showProfileVerification
              ? profileVerification?.status || ProfileVerificationStatus.UNVERIFIED
              : undefined
          }
          onClick={() => push(`${url}/personal-details`)}
        />
      </div>
      <GridContainer>
        {isMyFavouritesActive ? (
          <motion.div
            variants={childrenVariants}
            whileHover={{ scale: 1.01 }}
            className="mt-4 flex flex-col divide-y divide-grey-light lg:aspect-square"
          >
            <MainSectionTile
              shouldShowNewTag={shouldShowNewTagOnMeTab}
              icon={<MyFavouritesIcon className="h-8 w-8 lg:h-12 lg:w-12" />}
              title={t("my_favourites_homepage")}
              description={t("my_favourites_description")}
              to={`${url}/my-favourites`}
            />
          </motion.div>
        ) : null}
        {isPortfolioActive ? (
          <motion.div
            variants={childrenVariants}
            whileHover={{ scale: 1.01 }}
            className="mt-4 flex flex-col divide-y divide-grey-light lg:aspect-square"
          >
            <MainSectionTile
              icon={<PortfolioHomepageIcon className="h-8 w-8 lg:h-12 lg:w-12" />}
              title={t("portfolio_homepage")}
              description={t("portfolio_homepage_description")}
              to={`${url}/portfolio`}
            />
          </motion.div>
        ) : null}

        {isMyFilesActive ? (
          <motion.div
            variants={childrenVariants}
            whileHover={{ scale: 1.01 }}
            className="mt-4 flex flex-col divide-y divide-grey-light lg:aspect-square"
          >
            <MainSectionTile
              icon={<MyFilesIcon className="h-8 w-8 lg:h-12 lg:w-12" />}
              title={t("my_files_homepage")}
              description={t("my_files_description")}
              to={`${url}/my-files`}
            />
          </motion.div>
        ) : null}
      </GridContainer>
    </InnerPageWrapper>
  );
};
