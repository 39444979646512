import { NewsFeed } from "../types";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useRunOnMountUnmount, useCompleteFile } from "Hooks";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

type Props = {
  onSelectFile: (file: NewsFeed) => void;
};

export const useSelectNewsFeedItemOnMount = ({ onSelectFile }: Props) => {
  const { fileId } = useGetSearchParams({ paramList: ["fileId"] });
  const { getCompletedFileProps } = useCompleteFile();

  useRunOnMountUnmount({
    onMount: async () => {
      if (fileId) {
        const file = await contentDbStore.getItem(fileId);
        if (file) {
          const newsFeedItem = {
            ...file,
            isHighlighted: false,
            ...getCompletedFileProps(file),
            isRead: getCompletedFileProps(file).isCompleted,
            createdBy: file.info,
          };
          onSelectFile(newsFeedItem);
        }
      }
    },
  });
};
