import * as Yup from "yup";

import { useEolasForm } from "Hooks/useEolasForm";
import { useTranslation } from "react-i18next";
import { useCreateClinicalQuestion } from "modules/clinicalQuestions/data/useCreateClinicalQuestion";
import { clinicalQuestionStore, userStore } from "@eolas-medical/core";
import { useGetClinicalQuestionResponse } from "modules/clinicalQuestions/data/useGetClinicalQuestionResponse";
import { modalStore } from "Stores/ModalStore";
import { DisclaimerModal } from "../DisclaimerModal";
import { useSupportedRegion } from "Hooks/useSupportedRegion";

export type ClinicalQuestion = {
  question: string;
  notes: string;
};

const makeValidationSchema = (errorMessage: string) =>
  Yup.object({
    question: Yup.string()
      .trim()
      .min(5, errorMessage)
      .test({
        name: "wordCount",
        message: errorMessage,
        test: function (value) {
          if (!value) {
            return false;
          }
          const words = value.split(" ").filter(Boolean);
          return words.length >= 2;
        },
      }),
  });

export const useClinicalQuestionInput = () => {
  const { data, createClinicalQuestion } = useCreateClinicalQuestion();
  const { t } = useTranslation();
  const { supportedEolasRegion } = useSupportedRegion();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useEolasForm<ClinicalQuestion>({
    defaultValues: {
      question: "",
      notes: "",
    },
    mode: "onSubmit",
    validationSchema: makeValidationSchema(t("question_too_short")),
  });

  const onSubmit = handleSubmit(async ({ question, notes }) => {
    if (!clinicalQuestionStore.haveTermsBeenAccepted(userStore.userID)) {
      modalStore.openModal({
        name: "disclaimerModal",
        variant: "component",
        Component: <DisclaimerModal />,
      });
    }

    await createClinicalQuestion({
      text: question,
      notes: notes,
      userID: userStore.userID,
      region: supportedEolasRegion,
    });
  });

  const { progress, isSuccess } = useGetClinicalQuestionResponse({
    requestID: data?.requestID ?? "",
    averageExecutionTimeInSeconds: data?.averageExecutionTime,
  });

  return {
    control,
    onSubmit,
    setValue,
    watch,
    isSubmitting,
    errors,
    questionID: data?.requestID,
    progress,
    reset,
    isSubmitSuccessful,
    isSuccess,
  };
};
