import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Space } from "@eolas-medical/core";
import { useForm } from "react-hook-form";
import { PrivacyIcon, defaultSpace } from "Assets";
import { Button, FormElement, IconText, InnerModalWrapper, Text, Textarea, Title } from "UIKit";
import SpecialtiesList from "../../../SpecialtiesList/SpecialtiesList";
import { useSelectSpaceActions } from "../../../../hooks/useSelectSpaceActions";
import { useUserAccessStatus } from "modules/spaces/data/useUserAccessStatus";
import { BaseAppRoute } from "Utilities/types";

interface RequestAccessModalProps {
  space: Space;
}

export const PreviewSpaceModal = ({ space }: RequestAccessModalProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const {
    onSelectSpace,
    onRequestAccess,
    shouldAllowAccessLoading,
    requestingAccess,
    requestAccessSuccessful,
    shouldAllowUserAccess,
    userAccess,
  } = useSelectSpaceActions();

  const { isPendingAccessRequest, hasAccess, doesRequireTrustedEmail } = useUserAccessStatus(
    userAccess?.status || "",
  );

  const { control, register, watch } = useForm({
    defaultValues: {
      requestText: "",
    },
  });

  const isPublicSpace = space.accessType === "public";

  const canSendRequestText = userAccess && !isPublicSpace && !hasAccess && !isPendingAccessRequest;

  const requestText = watch("requestText");

  const handleClickButton = () => {
    if (isPublicSpace || hasAccess) {
      onSelectSpace(space.id);
      return;
    }

    onRequestAccess(space.id, requestText);
  };

  const renderButtonText = () => {
    if (!userAccess && !isPublicSpace) {
      return t("selectSpace_checking_permissions");
    }
    if (isPublicSpace || hasAccess) {
      return t("selectSpace_explore_space");
    }

    if (requestAccessSuccessful || isPendingAccessRequest) {
      return t("selectSpace_request_access_sent");
    }

    return t("selectSpace_request_access");
  };

  useEffect(() => {
    const checkPermissions = async () => {
      return shouldAllowUserAccess(space.id);
    };
    !isPublicSpace && checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InnerModalWrapper>
      <div className="h-65vh overflow-y-auto">
        <div className="p-8 flex flex-col justify-center space-y-6">
          <div className="flex flex-col justify-center items-center ">
            <img
              src={space?.image?.url || defaultSpace}
              className="rounded-lg w-104 h-48 md:h-72 shadow-lg object-cover"
            />
            <Title level={5} className="mt-4 text-center">
              {space.name}
            </Title>
            <div className="flex justify-center items-center space-x-2">
              <Text level={3}>
                {space.userCount}
                {space.userCount > 1 ? t("space_details_members") : t("space_details_member")}
              </Text>
              {!isPublicSpace ? (
                <IconText level={3} className="text-grey-700" icon={<PrivacyIcon />}>
                  {t("selectSpace_is_private")}
                </IconText>
              ) : null}
            </div>
          </div>
          <div>
            <div className="flex flex-col space-y-2">
              {space.description ? (
                <div>
                  <Title level={7}>{t("selectSpace_about")}</Title>
                  <Text level={1}>{space.description}</Text>
                </div>
              ) : null}
              <div>
                {space.specialty ? (
                  <>
                    <Title level={7}>{t("selectSpace_keywords")}</Title>
                    <div className="mt-2">
                      <SpecialtiesList specialties={space.specialty} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {canSendRequestText ? (
              <FormElement
                id="requestText"
                label={t("selectSpace_request_information_label")}
                control={control}
                labelClassName="text-sm md:text-base"
                className="mt-4"
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <Textarea
                      {...register("requestText")}
                      {...rest}
                      placeholder={t("selectSpace_request_information_placeholder")}
                      value={value}
                      className="resize-none h-24"
                    />
                  );
                }}
              </FormElement>
            ) : null}
            <div className="flex max-w-40 justify-center">
              <div className="flex space-x-2 mt-4">
                {doesRequireTrustedEmail ? (
                  <Button
                    size="sm"
                    disabled={shouldAllowAccessLoading}
                    onClick={() => {
                      push(`/${BaseAppRoute.myProfile}/personal-details`, { addEmail: true });
                    }}
                  >
                    <Text level={1} className="font-bold text-white">
                      {t("selectSpace_add_trusted_email_button")}
                    </Text>
                  </Button>
                ) : null}
                <Button
                  size="sm"
                  onClick={handleClickButton}
                  isLoading={requestingAccess}
                  disabled={requestAccessSuccessful}
                >
                  <Text level={1} className="font-bold text-white">
                    {renderButtonText()}
                  </Text>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
