import { useMutation } from "@tanstack/react-query";
import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { loginWithToken, logout } from "Pages/Authentication/auth.actions";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { LDFlagNames } from "Utilities/types";
import useUserDetails from "Hooks/useUserDetails";
import { hasStringProp, sectionStore, userStore } from "@eolas-medical/core";
import { syncWebAppData } from "Hooks";
import { useNavigateToSpace } from "Pages/Spaces/pages/SelectSpace/hooks/useNavigateToSpace";
import { useHistory } from "react-router-dom";

type Props = {
  callbackUrl?: string;
};

export const useAutoLogin = (props?: Props) => {
  const { onSelectSpace } = useSelectSpaceActions();
  const { isSharedAccount: isCurrentAccountDataSharedAccount } = useUserDetails();
  const { navigateToSpace } = useNavigateToSpace();
  const history = useHistory();

  const { flags } = useLaunchDarkly();

  const {
    mutate: autoLogin,
    isError,
    isLoading,
  } = useMutation({
    mutationKey: ["autoLogin"],
    mutationFn: async (token: string) => {
      const { data: loginData } = await loginTokenService.loginWithToken({
        token,
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });

      if (sectionStore.appID === loginData.appID && userStore.userID === loginData.userID) {
        if (!userStore.userSession.isLoggedIn) {
          await syncWebAppData({ isFromSoftLogoutState: true });
          userStore.setLoggedStatus(true);
        }
        navigateToSpace(
          hasStringProp(sectionStore.data.app, "name") ? sectionStore.data.app.name : "",
        );
        if (props?.callbackUrl) {
          history.replace(props.callbackUrl);
        }
        return;
      } else {
        await logout();
      }

      await loginWithToken(loginData);
      await onSelectSpace(loginData.appID);
      if (props?.callbackUrl) {
        history.replace(props.callbackUrl);
      }
    },
  });

  return { autoLogin, isError, isLoading, isCurrentAccountDataSharedAccount };
};
