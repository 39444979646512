import { sectionStore, userStore } from "@eolas-medical/core";
import { useCallback } from "react";
import OneSignal from "react-onesignal";
import { useHistory } from "react-router-dom";

import { useSpacesContext } from "modules/spaces";
import { useQueryClient } from "@tanstack/react-query";
import { BaseAppRoute } from "Utilities/types";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export const useSignoutSpace = ({ shouldPushNewRoute }: { shouldPushNewRoute: boolean }) => {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const { onClearSpace } = useSpacesContext();

  const signoutSpace = useCallback(async () => {
    sectionStore.clearStore();
    contentDbStore.clearStore();
    localSearchStore.clearStore();
    sectionStore.setIsAdmin(false);
    userStore.signOutOrganisation();
    queryClient.clear();
    onClearSpace();
    OneSignal.sendTag("departmentCreationDate", "null");
    if (shouldPushNewRoute) {
      push(`/${BaseAppRoute.selectSpace}`);
    }
  }, [push, onClearSpace, shouldPushNewRoute, queryClient]);

  return { signoutSpace };
};
