import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  eolasLogger,
  getCompletedChecklistsAndIncidentReportFiles,
  Maybe,
  sectionStore,
  syncCommunityData,
  syncMyFavourites,
  syncSpaceOrgData,
  syncUserData,
  updateOwnDepartments,
  UserData,
  userStore,
} from "@eolas-medical/core";
import { getSupportedRegion } from "./useSupportedRegion";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { addRetries } from "Utilities/helpers";
import { webStore } from "Stores/WebStore";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import {
  addToFileDbCallback,
  clearFiles,
  removeFromFileDbCallback,
  upsertChecklistsToSearchDb,
} from "Pages/Spaces/pages/Space/functions/fetchCallbacks";

export const makeUseAppSyncQueryKey = ({
  userData,
  spaceId,
  isLoggedIn,
  isInOrganisation,
  isAddingChecklistFiles,
}: {
  userData: Maybe<UserData>;
  spaceId: Maybe<string>;
  isLoggedIn: boolean;
  isInOrganisation: boolean;
  isAddingChecklistFiles: boolean;
}) => {
  return [
    "getFullAppData",
    userData && isLoggedIn ? getSupportedRegion(userData) : null,
    userData?.id && isLoggedIn ? userData.id : null,
    isInOrganisation ? spaceId : null,
    isAddingChecklistFiles,
  ];
};

export const syncWebAppData = async ({
  isFromSoftLogoutState,
}: { isFromSoftLogoutState?: boolean } = {}) => {
  const userData = userStore.userData;
  const spaceId = sectionStore.appID;
  const { isLoggedIn: savedIsLoggedIn, isInOrganisation } = userStore.userSession;
  if (!savedIsLoggedIn && !isFromSoftLogoutState) {
    webStore.setAppLastFetchTimestamp(null);
    return null;
  }
  const isLoggedIn = isFromSoftLogoutState || savedIsLoggedIn;
  const region = userData ? getSupportedRegion(userData) : null;
  const lastFetchTimestamp = contentDbStore.lastUpdatedTimestamp;
  const shouldFetchSpaceOrgData = Boolean(
    lastFetchTimestamp && !contentDbStore.isAddingChecklistFiles && spaceId && isInOrganisation,
  );
  const [spaceOrgData] = await Promise.all([
    shouldFetchSpaceOrgData
      ? syncSpaceOrgData({
          fetchAndReplaceAll: false,
          lastFileFetchTimestamp: lastFetchTimestamp,
          clearFileDbCallBack: clearFiles,
          addToFileDbCallback,
          removeFileFromDbCallback: removeFromFileDbCallback,
        })
      : null,
    isLoggedIn ? syncUserData() : null,
    isLoggedIn ? updateOwnDepartments({ userID: userStore.userID }) : null,
    isLoggedIn && region ? syncCommunityData({ region }) : null,
  ]);
  if (isLoggedIn && region) {
    addRetries(() => syncMyFavourites({ region }))().catch(eolasLogger.error);
  }

  const { hasUpdated: hasUpdatedChecklists } = await upsertChecklistsToSearchDb(
    spaceOrgData?.sections,
  );

  webStore.setAppLastFetchTimestamp(new Date().toISOString());

  if (spaceOrgData?.hasUpdatedFiles || hasUpdatedChecklists) {
    contentDbStore.setLastUpdatedTimestamp(new Date().toISOString());
    localSearchStore.persistDb();
  }
  if (spaceOrgData?.appUser.accessLevel === "admin") {
    getCompletedChecklistsAndIncidentReportFiles({
      updatedAt: lastFetchTimestamp || undefined,
      onAddFile: addToFileDbCallback,
      onRemoveFile: removeFromFileDbCallback,
    })
      .catch(eolasLogger.error)
      .then((result) => {
        if (result) {
          contentDbStore.setLastUpdatedTimestamp(new Date().toISOString());
        }
      });
  }
  return spaceOrgData;
};

/**
 * Hook for use in App.tsx
 */
export const useAppSync = () => {
  const userData = userStore.userData;
  const spaceId = sectionStore.appID;
  const { isLoggedIn, isInOrganisation } = userStore.userSession;
  const { isAddingChecklistFiles } = contentDbStore;

  const { refetch } = useQuery({
    queryKey: makeUseAppSyncQueryKey({
      userData,
      spaceId,
      isLoggedIn,
      isInOrganisation,
      isAddingChecklistFiles,
    }),
    queryFn: () => syncWebAppData(),
    staleTime: 30000,
    cacheTime: 500000,
  });

  return { refetch };
};

/**
 * This is just a wrapper hook for now
 * to prevent modifying lots of other files
 */
export const useRefetchAppData = () => {
  const { refetch: mainRefetch } = useAppSync();
  const refetch = useCallback(async () => {
    await mainRefetch();
  }, [mainRefetch]);

  return { refetch };
};
